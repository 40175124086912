import { Component, ElementRef, ViewChild } from "@angular/core";
import { PoBreadcrumb, PoDialogService, PoModalAction, PoModalComponent, PoPageAction, PoTableAction, PoTableColumn, PoTableColumnLabel, PoTableComponent, PoTableDetail, PoTextareaComponent, PoToasterType } from "@po-ui/ng-components";
import { environment } from "../../../../environments/environment";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { ClienteService } from "../../clientes/cliente.service";
import { OrcamentoService } from "../orcamento.service";
import { OrcamentoToPedidoDto } from "../orcamento-pedido-dto";
import { SerasaComponent } from "../../serasa/serasa.component";

@Component({
    selector: 'erpcloud-orcamento-aprova',
    templateUrl: './orcamento-aprova.component.html',
    styleUrls: ['./orcamento-aprova.component.css']
})
export class OrcamentoAprovaComponent {

    @ViewChild('consultaSerasaModal') consultaSerasaModal!: SerasaComponent;
    @ViewChild('aprovaOrcamentoModal', { static: true }) aprovaOrcamentoModal!: PoModalComponent;
    @ViewChild('orcamentoTable', { static: true }) orcamentoTable!: PoTableComponent;
    @ViewChild('txtAreaInput') txtAreaInput!: ElementRef;


    //pagnation
    moreItens: boolean = false;
    page: number = 1;
    pageSize: number = 20;
    tableItens: any[] = [];


    form: FormGroup;
    toasterType: PoToasterType = PoToasterType.Information;
    toasterMessage: string = '';
    toasterSupoortText: string = '';
    toasterAction: () => void = () => { };
    toasterActionLabel: string = '';
    clienteLiberado: boolean = false;
    isHideLoading = true;

    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Financeiro' }, { label: 'Aprovação Financeira' }]
    };
    readonly pageActions: Array<PoPageAction> = [
        {
            label: 'Atualizar Dados',
            action: this.resetTable.bind(this),
            icon: 'ph ph-floppy-disk',

        }
    ];


    readonly orcamentoServiceApi = environment.apiUrl + environment.versionApi + '/comercial/orcamento';
    readonly actions: PoTableAction[] = [
        {
            label: 'Aprovar Orçamento',
            action: this.aprovaOrcamento.bind(this),
            icon: 'ph ph-check-circle',

        },
        {
            label: 'Visualizar Orçamento',
            action: this.visualizaOrcamento.bind(this),
            icon: 'ph ph-eye',

        },
        {
            label: 'Reprovar Orçamento',
            type: 'danger',
            //action: this.deleteMessage.bind(this),
            icon: 'ph ph-seal-warning',
            separator: true
        }
    ];

    public readonly columns: Array<PoTableColumn> = [];

    constructor(private fb: FormBuilder,
        private poDialog: PoDialogService,
        private clienteService: ClienteService,
        private orcamentoService: OrcamentoService) {

        this.form = this.fb.group({
            orcamento: [''],
            emissao: [''],
            cliente: [''],
            condicao: [''],
            obs: [''],
        });
        this.columns = orcamentoService.orcamentoColumns;
        this.loadMore();
    }
    base64ToArrayBuffer(base64: string): ArrayBuffer {
        const binaryString = window.atob(base64); // Decodifica a string base64
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }
    resetTable() {
        this.loadMore(true);
    }
    loadMore(reset: boolean = false): void {

        this.isHideLoading = false;
        if (reset) {
            this.page = 1;
            this.orcamentoService.getOrcamentosAprovaFinanceiro(this.page, this.pageSize).subscribe((data) => {
                this.tableItens = data.items;
                this.moreItens = data.hasNext;
                this.page++;
                this.isHideLoading = true;
            }, (error) => {
                console.log(error);
                this.isHideLoading = true;
            });
        }
        else {
            this.orcamentoService.getOrcamentosAprovaFinanceiro(this.page, this.pageSize).subscribe((data) => {
                this.tableItens = [...this.tableItens, ...data.items];
                this.moreItens = data.hasNext;
                this.page++;
                this.isHideLoading = true;

            }, (error) => {
                console.log(error);
                this.isHideLoading = true;

            });
        }

    }
    test(item: any) {
        this.consultaSerasaModal.abrirConsulta();
    }
    visualizaOrcamento(orcamento: any) {

        this.isHideLoading = false;

        this.orcamentoService.visualizaOrcamento(orcamento.id).subscribe((data) => {
            const arrayBuffer = this.base64ToArrayBuffer(data);
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            this.isHideLoading = true;
        }, (error) => {
            console.log(error);
            this.isHideLoading = true;
        });

    }
    finalizaAprovacao() {

        this.isHideLoading = false;
        var orcamento: OrcamentoToPedidoDto = {
            idPedido: this.form.get('orcamento')?.value,
            obsFinanceiro: this.form.get('obs')?.value,
        };

        this.orcamentoService.convertToPedido(orcamento).subscribe((data) => {
            this.aprovaOrcamentoModal.close();
            this.isHideLoading = true;
            this.resetTable();
        }, error => {
            console.error('Erro ao buscar dados:', error);
            this.isHideLoading = true;
        });
    }
    aprovaOrcamento(orcamento: any) {

        if (orcamento.cp != 'S') {
            this.poDialog.alert({ 'title': 'Orçamento já aprovado', 'message': 'O orçamento já foi aprovado anteriormente', 'ok': () => { } });
            return;
        }

        this.isHideLoading = false;
        this.form.get('orcamento')?.setValue(orcamento.id.toString().padStart(6, '0'));
        this.form.get('emissao')?.setValue(new Date(orcamento.dataInclusao).toLocaleString());
        this.form.get('cliente')?.setValue(orcamento.cliente.razao);
        this.form.get('condicao')?.setValue(orcamento.condPgto.descricao);

        this.clienteService.getClienteById(orcamento.cliente.id).subscribe((cliente) => {
            const dataAtual = new Date();
            if (!cliente.dataExpiraSerasa) {
                this.toasterMessage = "Cliente necessita de SERASA para liberação";
                this.toasterSupoortText = "Para continuar com a aprovação do orçamento é necessário consultar o SERASA do cliente";
                this.toasterActionLabel = "Consultar Serasa";
                this.toasterType = PoToasterType.Error;

                this.toasterAction = () => {
                    this.consultaSerasaModal.cliente = cliente;
                    this.consultaSerasaModal.abrirConsulta();
                    this.aprovaOrcamentoModal.close();
                    console.log('Consultar Serasa');
                };
            }
            else if (new Date(cliente.dataExpiraSerasa) < dataAtual) {
                this.toasterMessage = "Cliente está com o SERASA vencido (" +
                    new Date(cliente.dataExpiraSerasa).toLocaleDateString() + ")";
                this.toasterActionLabel = "Consultar Serasa";
                this.toasterSupoortText = "A consulta do SERASA já realizada para esse cliente expirou, é necessário realizar uma nova consulta";

                this.toasterType = PoToasterType.Warning

                this.toasterAction = () => {
                    this.consultaSerasaModal.cliente = cliente;
                    this.consultaSerasaModal.abrirConsulta();
                    this.aprovaOrcamentoModal.close();
                    console.log('Consultar Serasa');
                };
            }
            else {
                this.toasterMessage = "Cliente liberado para compra (Serasa OK)";
                this.toasterSupoortText = "";

                this.toasterType = PoToasterType.Success;
                this.toasterActionLabel = "";
                this.toasterAction = () => { };

                this.clienteLiberado = true;

            }
            this.aprovaOrcamentoModal.open();
            console.log(orcamento);
            this.isHideLoading = true;

        }, error => {
            console.error('Erro ao buscar dados:', error);
            this.isHideLoading = true;
        });
        // this.toasterMessage = "Cliente com Serasa vencido";
        // this.toasterActionLabel = "Consultar Serasa";
        // this.toasterType = PoToasterType.Warning;

        // this.toasterAction = () => {
        //     this.aprovaOrcamentoModal.close();
        //     console.log('Consultar Serasa');
        // };


    }
    formatValue(value: number): string {
        return value.toString().padStart(5, '0');
    }

}