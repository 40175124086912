import { Component } from '@angular/core';
import { PoBreadcrumb, PoNotificationService, PoPageAction, PoRadioGroupOption, PoTagType } from '@po-ui/ng-components';
import { environment } from '../../../environments/environment';
import { RaioDto } from '../orcamento/raio-dto';
import { PedidoItem } from '../orcamento/pedido';
import { CondPgto } from '../condpgto/condpgto';
import { CondPgtoService } from '../condpgto/condpgto.service';
import { ProdutoService } from '../produto/produto.service';
import { Produto } from '../produto/produto';
import { TenantService } from '../../core/auth/tenant.service';
import { Tenants } from '../../core/auth/tenant';
import { OrcamentoService } from '../orcamento/orcamento.service';
import { CadastroHelperService } from '../../core/helpers/cadastro-helper.service';
import { DistanceRequestDto } from '../../core/helpers/distance-request-dto';
import { DistanceResponseDto } from '../../core/helpers/distance-response-dto';
import { SimpleDistanceResponseDto } from '../../core/helpers/simple-distance-request-dto';
import { Vendedor } from '../vendedor/vendedor';
import { UserService } from '../../core/user/user.service';
import { User } from '../../core/user/user';
import { Router } from '@angular/router';


@Component({
    selector: 'erpcloud-calculadora',
    templateUrl: './calculadora.component.html',
    styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent {

    isLoadind = false;
    readonly produtoServiceApi = environment.apiUrl + environment.versionApi + '/produto/combo';
    readonly pagamentoServiceApi = environment.apiUrl + environment.versionApi + environment.pagamentoServiceApi;
    //configForm!: FormGroup;
    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Orçamentos' }, { label: 'Calculadora de Preços' }]
    };

    readonly pageActions: Array<PoPageAction> = [
        {
            label: 'Salvar Configurações',
            //action: this.atualizaTenantConfig.bind(this),
            icon: 'ph ph-floppy-disk',

        },
        {
            label: 'Desfazer Alterações',
            //action: this.loadTenantInfo.bind(this),
            icon: 'ph ph-warning-circle',
            //type: 'danger',
        }
    ];

    percentualAcrescimo: number = 0;
    totalAcrescimo: number = 0;
    totalDesconto: number = 0;
    percentualDesconto: number = 0;
    totalDescontoPedido: number = 0;
    totalDescontoVendedor: number = 0;
    valorAdicional: number = 0;

    bombaSelecionada: number = 1;
    msgBomba: string = '';
    titleMsgBomba: string = '';
    valorMinimoBomba: number = 0;
    valorMetroBomba: number = 0;
    valorTotalBomba: number = 0;

    vendedor!: Vendedor;
    userInfo!: User;
    raioConfigs: RaioDto[] = [];
    poTagDanger: PoTagType = PoTagType.Danger;
    poTagSuccess: PoTagType = PoTagType.Success;
    itemSelecionado: Produto = new Produto();
    pagamentoSelecionado: CondPgto = new CondPgto();
    totalKm: number = 0;
    quantidade: number = 1;
    tenantInfo: Tenants = new Tenants();
    cep: string = '';

    public poOptions: Array<PoRadioGroupOption> = [
        // { label: 'Sem Bomba', value: '1' },
        // { label: 'BT Bomba', value: '2' },
        // { label: 'Bomba Simples', value: '3' }
    ]

    constructor(private condPgtoService: CondPgtoService,
        private userService: UserService,
        private tenantService: TenantService,
        private cadastroHelper: CadastroHelperService,
        private poNotification: PoNotificationService,
        private orcamentoService: OrcamentoService,
        private router: Router,
        private produtoService: ProdutoService) {

        this.tenantService.getTenantInfo().subscribe(tenant => {
            this.tenantInfo = tenant;
        });
        this.userService.getUser().subscribe((data) => {
            this.userInfo = data as User;
            this.orcamentoService.getVendedorById(this.userInfo.id_vendedor).subscribe((data) => {
                this.vendedor = data;
                console.log(this.vendedor);

                if (this.vendedor.id == 0) {
                    this.poNotification.error('Usuário sem vendedor associado, favor verificar cadastro');
                    this.router.navigate(['/home']);
                }
            });
        });

        this.orcamentoService.getTipoBombas().subscribe(data => {
            this.poOptions = data;
            this.bombaSelecionada = 1;
        });

        this.orcamentoService.getRaioConfigs().subscribe(data => {
            this.raioConfigs = data;
            console.log(this.raioConfigs);
        });

    }

    ngOnInit() {
        // Lógica de inicialização do componente
    }

    get totalQuantidade(): number {
        return Number(this.itemSelecionado.precoLista) * this.quantidade;
    }
    get totalVolume(): number {
        return Number(this.quantidade);
    }
    selecionaBomba(value: number) {
        this.bombaSelecionada = value;
        let bombaSelecionada = this.poOptions.find(bomba => bomba.value == this.bombaSelecionada) as any;
        this.msgBomba = 'Valor mínimo: ' + this.formatarParaMoeda(bombaSelecionada.valorMinimo) + ' - Valor metro: ' + this.formatarParaMoeda(bombaSelecionada.valorMetro);
        this.titleMsgBomba = bombaSelecionada.label + ' Selecionada';

        this.valorMinimoBomba = Number(bombaSelecionada.valorMinimo);
        this.valorMetroBomba = Number(bombaSelecionada.valorMetro);

        this.calculaValorBomba();
    }
    formatarParaMoeda(valor: number): string {
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    calculaValorBomba() {
        this.valorTotalBomba = Number(this.totalVolume) * Number(this.valorMetroBomba);
        if (this.valorTotalBomba < this.valorMinimoBomba) {
            this.valorTotalBomba = this.valorMinimoBomba;
        }

        console.log('Valor da bomba:' + this.valorTotalBomba);
    }
    onChangeProduto(produto: number) {

        this.produtoService.getProdutoById(produto).subscribe((data) => {
            this.itemSelecionado = data;
            this.calculaAcrescimo();
            this.calculaDesconto();
            this.calculaValorBomba();
        });

    }
    onChangeQuantidade(quantidade: number) {
        this.quantidade = Number(quantidade);
        this.calculaAcrescimo();
        this.calculaDesconto();
        this.calculaValorBomba();
    }
    onChangePagamento(pgto: number) {

        this.condPgtoService.getCongPgto(pgto).subscribe((data) => {
            this.pagamentoSelecionado = data;
            this.calculaDesconto();
        });

    }
    calculaDescontoVendedor(event: any) {
        console.log(event);
        if (event) {
            this.totalDescontoVendedor = (this.totalQuantidade + this.totalAcrescimo) * (this.vendedor.maxDiscount / 100);
        }
        else {
            this.totalDescontoVendedor = 0;
        }
    }
    calculaDesconto() {
        this.percentualDesconto = this.pagamentoSelecionado?.desconto ?? 0;
        this.totalDesconto = (this.totalQuantidade + this.totalAcrescimo) * (this.percentualDesconto / 100);
    }
    onCepChange(event: any) {

        this.cep = event;
        console.log(this.cep);
        if (this.cep.length == 9) {
            console.log(this.cep);
            const requestDto = new DistanceRequestDto();
            requestDto.mode = 'driving';
            requestDto.origin = this.tenantInfo.cepDistance;
            requestDto.destination = this.cep;


            this.cadastroHelper.getDistanceBetweenCepsGoogle(requestDto).subscribe((data: SimpleDistanceResponseDto) => {
                console.log(data);
                this.totalKm = Number(data.distanceValue ?? 0);

                console.log(this.totalKm);
                this.calculaAcrescimo();
                this.calculaDesconto();
            });

        }


    }
    calculaAcrescimo() {
        this.percentualAcrescimo = this.getAcrecimoRaio();
        this.totalAcrescimo = this.totalQuantidade * (this.percentualAcrescimo / 100);
        //(totalAcrescimo / 100) * totalQuantidade
        console.log('Acrescimo:' + this.totalAcrescimo);
    }
    getAcrecimoRaio(): number {

        let acrescimo = 0;

        for (let raio of this.raioConfigs) {
            if ((this.totalKm / 1000) < raio.raio) {
                return raio.acrescimo;
            }
        }

        return acrescimo;
    }


}