<po-page-default [p-breadcrumb]="breadcrumb" p-title="Inclusão de Orçamento"
    p-subtitle="Inclusão de novo orçamento no sistema.">
    <div class="po-row po-md-12 po-lg-12">
        <po-stepper #stepper class="po-row po-md-12 po-lg-12">
            <po-step p-label="Cliente" [p-can-active-next-step]="verificaCliente.bind(this)">
                <div class="po-row">
                    <po-widget class="po-lg-12">
                        <div class="po-row po-mb-3">
                            <div class="po-row">
                                <div class="po-md-12 po-font-title">Selecione um cliente para iniciar</div>
                                <span class="po-lg-12 po-md-12 po-sm-12 po-mt-1 po-font-text-uppercase">
                                    <span class="ph ph-address-book"></span>Vendedor: {{vendedor.nome}}</span>

                            </div>

                            <po-combo class="po-lg-12 po-mt-5" p-placeholder="Escolha um Cliente" name="combo"
                                p-icon="po-icon-user" p-label="Selecione um Cliente" p-field-label="razao"
                                p-field-value="id" [p-required]="true" [p-show-required]="true"
                                (p-change)="onChangeCliente($event)" p-help="Selecione um cliente para o orçamento"
                                [p-filter-service]="clienteServiceApi">
                            </po-combo>

                            <div class="po-row po-mt-2">
                                <div class="po-md-12 po-font-title">Ou cadastre um novo...</div>

                            </div>

                            <hr />

                            <div class="po-row">
                                <po-button class="po-md-12 po-mt-1 po-lg-3" p-label="Novo Cliente"
                                    p-icon="po-icon po-icon-user-add"
                                    (p-click)="this.cadastroClienteModel.novoCliente()">
                                </po-button>
                            </div>

                        </div>
                        <ng-container *ngIf="clienteChange">
                            <div class="po-row">
                                <div class="po-row po-mb-3">
                                    <po-divider class="po-md-12" p-label="Status"></po-divider>
                                    <po-tag [p-type]="poTag" class="po-md-3" p-icon="po-icon-info" p-label="Status"
                                        p-value="Liberado"></po-tag>
                                    <po-info class="po-md-3" p-label="Razão Social" [p-value]="clienteChange.razao">
                                    </po-info>
                                    <po-info class="po-md-3" p-label="Contato" [p-value]="clienteChange.contato">
                                    </po-info>
                                    <po-info class="po-md-3 po-mb-md-2" p-label="CNPJ" [p-value]="clienteChange.cnpj">
                                    </po-info>
                                    <div class="po-row po-mb-4">
                                        <po-progress class="po-lg-12 po-mt-4" [p-value]="78"
                                            [p-text]="'Limite: ' + ((clienteChange.limite ?? 0) | currency:'BRL':'symbol':'1.2-2')"
                                            p-info-icon="po-icon po-icon-info" p-info="% Utilização de limite:"
                                            p-show-percentage="true"></po-progress>
                                    </div>
                                </div>
                            </div>
                            <div class="po-row">
                                <po-button class="po-md-12 po-mt-1 po-lg-12" p-label="Continuar"
                                    (p-click)="stepper.next()" p-icon="po-icon po-icon-arrow-right" p-kind="primary">
                                </po-button>
                            </div>
                        </ng-container>
                    </po-widget>
                </div>
            </po-step>
            <po-step p-label="Localização Obra" [p-can-active-next-step]="verificaEndereco.bind(this)">
                <div class="po-row">
                    <po-widget class="po-lg-12">
                        <div class="po-row">
                            <div class="po-md-12 po-font-title">Onde será realizada a entrega?</div>
                            <span class="po-lg-12 po-md-12 po-sm-12 po-mt-1 po-font-text-uppercase">
                                <span class="ph ph-identification-badge"></span>Vendedor: {{vendedor.nome}}</span>
                        </div>

                        <div class="po-row">
                            <div class="po-lg-12 po-md-12 po-sm-12 po-font-text-uppercase"><span
                                    class="ph ph-user-circle"></span>Cliente:
                                {{clienteChange?.razao ?? ''}}</div>
                            <!-- <div class="po-md-12 po-font-text-small-bold">Distância máxima permitida: 40km</div> -->
                        </div>
                        <form [formGroup]="enderecoForm">
                            <div class="po-row po-mt-5">
                                <po-input #cepButton formControlName="cepButton" p class="po-md-6" name="Informe o CEP:"
                                    p-field-label="label" p-auto-focus="true" p-label="CEP"
                                    p-help="Informe o CEP para localização da obra" p-mask="99999-999"
                                    (p-change)="onCepChange($event)" (p-change-model)="onCepChange($event)"
                                    p-icon="ph ph-map-pin" p-placeholder="Informe o CEP" p-required="true"
                                    [p-show-required]="true" p-clean>
                                </po-input>
                                <po-button [p-disabled]="this.cep.length < 8" class="po-md-6 po-mt-4"
                                    p-label="Consulta Endereço" p-icon="po-icon po-icon-search"
                                    (p-click)="consultaCep()" [p-loading]="cepLoading">
                                </po-button>
                            </div>


                            <po-divider class="po-md-12 po-mt-3" p-label="Endereço"></po-divider>
                            <div class="po-row ">
                                <po-input class="po-lg-9" formControlName="address" p-icon="ph ph-map-pin-area"
                                    [p-show-required]="true"  p-required="true" p-label="Endereço">
                                </po-input>

                                <po-number #numberInput class="po-lg-3" formControlName="number" p-required="true"
                                    [p-show-required]="true" p-label="Número">
                                </po-number>
                            </div>
                            <div class="po-row">
                                <po-input class="po-lg-5" formControlName="bairro" p-icon="ph ph-map-pin-area"
                                    [p-show-required]="true"  p-required="true" p-label="Bairro">
                                </po-input>
                                <po-input class="po-lg-4" formControlName="city" p-icon="ph ph-map-pin-area"
                                    [p-show-required]="true"  p-required="true" p-label="Cidade">
                                </po-input>

                                <po-input class="po-lg-3" formControlName="uf" p-required="true" [p-show-required]="true"  p-label="Estado">
                                </po-input>
                            </div>

                            <div class="po-row po-mt-3">
                                <po-divider class="po-md-12" p-label="Informações adicionais"></po-divider>

                                <po-number formControlName="limiteVolume" class="po-lg-4 po-md-12"
                                    p-help="Limite do volume permitido na obra" [p-max]="20"
                                    p-icon="ph ph-truck-trailer" p-label="Limite de Volume" [p-show-required]="true"
                                    p-required="true">
                                </po-number>

                                <po-combo formControlName="horaEntrega" [p-filter-service]="serviceSections"
                                    class="po-lg-4 po-md-12" p-help="Hora prevista da entrega" p-icon="ph ph-clock"
                                    p-field-label="name" p-field-value="id" p-label="Hora da Entrega"
                                    [p-show-required]="true" [p-required]="true">
                                </po-combo>

                                <po-datepicker formControlName="prevFaturamento" class="po-lg-4 po-md-12"
                                    [p-iso-format]="poDatepickerIsoFormat" p-icon="ph ph-map-pin-area"
                                    p-label="Previsão de Entrega" p-help="Data prevista da entrega"
                                    [p-show-required]="true" p-required="true">
                                </po-datepicker>

                                <po-textarea formControlName="obsPedido" class="po-md-12 po-mt-1"
                                    p-help="Informações adicionais sobre a entrega" p-icon="ph ph-clock"
                                    p-label="Informações Adicionais" [p-show-required]="true">
                                </po-textarea>

                            </div>

                            <div class="po-row po-mt-3">
                                <div [hidden]="this.totalKm == 0" class="po-md-12 po-font-text-uppercase">Distância Calculada: {{this.distance}}</div>
                                <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Voltar"
                                    (p-click)="stepper.previous()" p-icon="po-icon po-icon-arrow-left">
                                </po-button>
                                <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Continuar"
                                    (p-click)="stepper.next()" p-icon="po-icon po-icon-arrow-right" p-kind="primary"
                                    [p-disabled]="(!enderecoForm.valid) || flagErroCep">
                                </po-button>
                            </div>

                        </form>

                    </po-widget>

                </div>

            </po-step>
            <po-step p-label="Serviços" [p-can-active-next-step]="verificaProduto.bind(this)">
                <po-widget class="po-lg-12">


                    <div class="po-md-12 po-font-title">Selecione os produtos:</div>
                    <span class="po-lg-12 po-md-12 po-sm-12 po-mt-1 po-font-text-uppercase">
                        <span class="ph ph-identification-badge"></span>Vendedor: {{vendedor.nome}}</span>
                    <div class="po-lg-12 po-md-12 po-sm-12 po-font-text-uppercase"><span
                            class="ph ph-user-circle"></span>Cliente:
                        {{clienteChange?.razao ?? ''}}</div>
                    <div class="po-md-12 po-font-text-small-bold">Inclua os itens em seu pedido</div>

                    <po-divider class="po-md-12 po-mt-3" p-label="TIPO DE BOMBA"></po-divider>

                    <po-combo #prodCombo class="po-lg-12" p-placeholder="Adicione o tipo de bomba" name="combo"
                        p-label="Selecione o tipo de bomba" p-field-label="label" p-icon="ph ph-package" p-field-value="value"
                        [p-required]="true" [p-show-required]="true" p-hide-columns-manager="true"
                        p-placeholder="Adicione a bomba do orçamento" p-help="Escolha o tipo de bomba para o pedido"
                        [p-options]="poOptions" (p-change)="selecionaBomba($event)">
                    </po-combo>

                    <!-- <po-toaster class="po-md-12" [p-hide]="(bombaSelecionada == 1 || bombaSelecionada == 3)"
                        p-message="BT Bomba Selecionada" p-show-close="false" [p-support-message]="msgBomba"
                        [p-message]="titleMsgBomba" [p-type]="poToasterType">
                    </po-toaster>

                    <po-toaster class="po-md-12"
                        [p-hide]="((bombaSelecionada ?? 1) == 1 || (bombaSelecionada ?? 1) == 2)"
                        [p-message]="titleMsgBomba" p-show-close="false" [p-support-message]="msgBomba"
                        [p-type]="poToasterType">
                    </po-toaster> -->

                    <div class="po-row po-mt-3">
                        <po-divider class="po-md-12" p-label="Itens"></po-divider>
                    </div>

                    <div class="po-row">

                        <po-table p-container="border" class="po-md-12" [p-items]="pedidoItens" [p-columns]="columns"
                            [p-actions]="actions" p-striped="true" p-text-wrap="true" p-hide-columns-manager="true">

                        </po-table>

                        <po-button class="po-md-12 po-lg-3 po-mt-1" p-label="Adicionar Produto"
                            p-icon="ph ph-plus-circle" (p-click)="incluirItem()">
                        </po-button>


                        <po-divider class="po-lg-12 po-mt-3" p-label="Valores Adicionais"></po-divider>


                        <form class="po-lg-12" [formGroup]="adicionalForm">
                            <div class="po-lg-12">
                                <po-switch class="po-md-12 po-lg-12" p-label="Incluir Valores Adicionais"
                                    p-icon="ph ph-percent" p-label-off="Não Aplicado" p-label-on="Aplicado"
                                    (p-change)="onChangeValorAdicional($event)" p-label-off="Não Aplicado"
                                    p-label-on="Aplicado">
                                </po-switch>
                            </div>

                            <div *ngIf="temValorAdicional" class="po-lg-12">
                                <po-number formControlName="valorAdicional" class="po-md-3 po-lg-3"
                                    (p-change)="onChangeAdicional($event)" p-label="Valor Adicional"
                                    name="valorAdicional" p-field-label="label" p-icon="ph ph-money" p-auto-focus="true"
                                    p-label="Total">
                                </po-number>
                                <po-multiselect formControlName="motivoValorAdicional" class="po-md-9 po-lg-9"
                                    name="multiselect" (p-change)="onChangeMotivoAdicional($event)"
                                    p-placeholder="Selecione os motivos para os valores adicionais"
                                    p-label="Selecione o motivo do valor adicional:" [p-options]="adicionalOptions">
                                </po-multiselect>
                            </div>

                        </form>


                        <div class="po-row po-mt-1">
                            <po-divider class="po-md-12" p-label="Totais"></po-divider>
                            <div class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                                Valor da Bomba: {{valorTotalBomba |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>

                            <div class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                                Valor dos Serviços: {{totalQuantidade |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>

                            <div *ngIf="totalMetragemMinimal > 0" class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                                Adicional Qtd Mínima: {{totalMetragemMinimal |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>

                            <div *ngIf="percentualAcrescimo > 0" class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                                Valor de acréscimos por
                                Km ({{ percentualAcrescimo }}%) :
                                {{ totalAcrescimo |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>
                            <div *ngIf="valorAdicional > 0" class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                                Valor Adicional:
                                {{ valorAdicional |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>
                            <div *ngIf="percentualDesconto > 0" class="po-md-12 po-font-text-large-bold">
                                <po-tag p-value="-" [p-type]="poTagSuccess"></po-tag>
                                Desconto Pagamento: ({{
                                percentualDesconto }}%) :
                                {{ totalDesconto |
                                currency:'BRL':'symbol':'1.2-2'}}
                            </div>

                            <div class="po-md-12 po-font-title po-mt-2">Total do Pedido: {{
                                totalQuantidade +
                                totalAcrescimo -
                                totalDesconto +
                                valorTotalBomba +
                                totalMetragemMinimal +
                                valorAdicional |
                                currency:'BRL':'symbol':'1.2-2'}}</div>
                        </div>

                    </div>



                    <div class="po-row po-mt-5">
                        <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Voltar" (p-click)="stepper.previous()"
                            p-icon="po-icon po-icon-arrow-left">
                        </po-button>
                        <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Continuar" (p-click)="stepper.next()"
                            p-icon="po-icon po-icon-arrow-right" p-kind="primary"
                            [p-disabled]="this.pedidoItens.length == 0 ? true : false">
                        </po-button>
                    </div>
                </po-widget>
            </po-step>
            <po-step p-label="Pagamento" [p-can-active-next-step]="verificaCondicao.bind(this)">
                <po-widget class="po-lg-12">
                    <div class="po-row">

                        <div class="po-row">
                            <div class="po-md-12 po-font-title">Selecione a condição de pagamento</div>
                        </div>
                        <div class="po-row">
                            <span class="po-lg-12 po-md-12 po-sm-12 po-mt-1 po-font-text-uppercase">
                                <span class="ph ph-identification-badge"></span>Vendedor: {{vendedor.nome}}</span>
                            <div class="po-lg-12 po-md-12 po-sm-12 po-font-text-uppercase"><span
                                    class="ph ph-user-circle"></span>Cliente:
                                {{clienteChange?.razao ?? ''}}</div>
                            <div class="po-md-12 po-font-text-small-bold">Informe a condição para o pagamento</div>
                        </div>
                        <po-combo class="po-lg-12 po-mt-5" p-placeholder="Condição de Pagamento" name="combo"
                            p-icon="ph ph-money" p-label="Selecione uma Condição de Pagamento" p-field-label="descricao"
                            p-field-value="id" [p-required]="true" [p-show-required]="true"
                            (p-change)="onChangePgto($event)" p-help="Selecione uma condição para o pagamento"
                            [p-filter-service]="pagamentoServiceApi">
                        </po-combo>

                    </div>
                    <div *ngIf="condPgto" class="po-md-12 po-font-text po-mb-3">
                        Total de Parcelas: {{condPgto.qtdParcelas ?? 1}}
                    </div>
                    <div *ngIf="condPgto" class="po-md-12 po-font-text po-mb-3">
                        <!-- <po-button class="po-md-12 po-mt-1 po-lg-3" p-label="Desconto Vendedor" p-icon="ph ph-percent"
                            (p-click)="calculaDescontoVendedor()">
                        </po-button> -->
                        <po-switch class="po-md-12 po-lg-3" p-label="Desconto Vendedor {{ vendedor.maxDiscount }}%"
                            p-icon="ph ph-percent" p-label-off="Não Aplicado" p-label-on="Aplicado"
                            p-help="Desconto máximo que o vendedor pode aplicar (pode ser negado pela diretoria)"
                            (p-change)="calculaDescontoVendedor($event)">
                            p-icon="ph ph-percent" p-label-off="Não Aplicado" p-label-on="Aplicado">
                        </po-switch>
                    </div>
                    <div class="po-row">
                        <po-toaster class="po-md-12" [p-hide]="(this.condPgto?.parc1 ?? 0) == 0"
                            p-message="Pagamento Financiado" p-show-close="false"
                            p-support-message="Essa condição poderá requerer análise de crédito em orgão de proteção do consumidor."
                            [p-type]="poToasterType">
                        </po-toaster>
                    </div>
                    <div class="po-row po-mt-1">
                        <po-divider class="po-md-12" p-label="Totais"></po-divider>
                        <div class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                            Valor da Bomba: {{valorTotalBomba |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>

                        <div class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                            Valor dos Serviços: {{totalQuantidade |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>

                        <div *ngIf="totalMetragemMinimal > 0" class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                            Adicional Qtd Mínima: {{totalMetragemMinimal |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>

                        <div *ngIf="percentualAcrescimo > 0" class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                            Valor de acréscimos por
                            Km ({{ percentualAcrescimo }}%) :
                            {{ totalAcrescimo |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>
                        <div *ngIf="valorAdicional > 0" class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                            Valor Adicional:
                            {{ valorAdicional |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>
                        <div *ngIf="percentualDesconto > 0" class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="-" [p-type]="poTagSuccess"></po-tag>
                            Desconto Pagamento: ({{
                            percentualDesconto }}%) :
                            {{ totalDesconto |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>
                        <div *ngIf="totalDescontoVendedor > 0" class="po-md-12 po-font-text-large-bold">
                            <po-tag p-value="-" [p-type]="poTagSuccess"></po-tag>
                            Desconto Vendedor: ({{
                            vendedor.maxDiscount }}%) :
                            {{ totalDescontoVendedor |
                            currency:'BRL':'symbol':'1.2-2'}}
                        </div>

                        <div class="po-md-12 po-font-title po-mt-2">Total do Pedido: {{
                            totalQuantidade +
                            totalAcrescimo -
                            totalDesconto -
                            totalDescontoVendedor +
                            valorTotalBomba +
                            totalMetragemMinimal +
                            valorAdicional |
                            currency:'BRL':'symbol':'1.2-2'}}</div>
                    </div>
                    <div class="po-row po-mt-5">
                        <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Voltar" (p-click)="stepper.previous()"
                            p-icon="po-icon po-icon-arrow-left">
                        </po-button>
                        <po-button class="po-md-6 po-mt-1 po-lg-6" p-label="Continuar" (p-click)="stepper.next()"
                            p-icon="po-icon po-icon-arrow-right" p-kind="primary" [p-disabled]="!enderecoForm.valid">
                        </po-button>
                    </div>
                </po-widget>
            </po-step>
            <po-step p-label="Finalização">
                <po-widget class="po-lg-12">
                    <div class="po-row po-mb-3">

                        <div class="po-row">
                            <div class="po-md-12 po-font-title">Confira os Dados do Orçamento</div>
                        </div>
                        <span class="po-lg-12 po-md-12 po-sm-12 po-mt-1 po-font-text-uppercase">
                            <span class="ph ph-identification-badge"></span>Vendedor: {{vendedor.nome}}</span>
                        <div class="po-lg-12 po-md-12 po-sm-12 po-font-text-uppercase"><span
                                class="ph ph-user-circle"></span>Cliente:
                            {{clienteChange?.razao ?? ''}}</div>
                        <div class="po-row po-mt-5">
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Nome"
                                p-value="{{clienteChange?.razao ?? ''}}">
                            </po-info>
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Endereço:"
                                p-value="{{clienteChange?.endereco}}">
                            </po-info>
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Cidade:"
                                p-value="{{clienteChange?.cidade}}">
                            </po-info>
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Total KM (Percurso):"
                                p-value="{{distance}}">
                            </po-info>
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Qtd Caminhões Sugeridos:"
                                p-value="{{(this.totalVolume / this.volumePermitido) | number:'1.0'}}">
                            </po-info>
                        </div>

                        <po-divider class="po-md-12" p-label="Itens"></po-divider>
                        <div class="po-row" *ngFor="let item of this.pedidoItens">
                            <po-info class="po-lg-6 po-font-text-uppercase" p-label="Código"
                                [p-value]="item.codigoInterno">
                            </po-info>
                            <po-info class="po-lg-3 po-font-text-uppercase" p-label="Qtd"
                                [p-value]="(item.quant | number) ?? ''"></po-info>
                            <po-info class="po-lg-3 po-font-text-uppercase" p-label="Valor"
                                [p-value]="(item.valorTotal | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                        </div>

                        <po-divider class="po-md-12 po-font-text-uppercase" p-label="Outros Valores"></po-divider>
                        <div class="po-row">
                            <po-info *ngIf="totalMetragemMinimal > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Adicional Metragem Mínima"
                                [p-value]="(totalMetragemMinimal | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                            <po-info *ngIf="valorTotalBomba > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Valor Bomba"
                                [p-value]="(valorTotalBomba | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                            <po-info *ngIf="totalAcrescimo > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Acréscimos por distância (KM)"
                                [p-value]="(totalAcrescimo | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                            <po-info *ngIf="valorAdicional > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Outros Acréscimos"
                                [p-value]="(valorAdicional | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                        </div>

                        <po-divider class="po-md-12 po-font-text-uppercase" p-label="Descontos"></po-divider>
                        <div class="po-row">
                            <po-info *ngIf="totalDesconto > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Desconto Condição de Pagamento"
                                [p-value]="(totalDesconto | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>
                            <po-info *ngIf="totalDescontoVendedor > 0" class="po-lg-12 po-font-text-uppercase"
                                p-label="Desconto Vendedor"
                                [p-value]="(totalDescontoVendedor | currency:'BRL':'symbol':'1.2-2') ?? ''"></po-info>

                        </div>

                        <div class="po-row po-mt-1">
                            <po-divider class="po-md-12 po-font-text-uppercase" p-label="Faturamento"></po-divider>

                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Condição de Pagamento"
                                p-value="{{condPgto?.descricao}}">
                            </po-info>
                            <po-info class="po-lg-12 po-font-text-uppercase" p-label="Valor do Orçamento:" p-value="{{
                                totalQuantidade +
                                totalAcrescimo -
                                totalDesconto -
                                totalDescontoVendedor +
                                valorTotalBomba +
                                totalMetragemMinimal +
                                valorAdicional |
                                currency:'BRL':'symbol':'1.2-2'}}">
                            </po-info>
                        </div>

                        <div class="po-row">
                            <po-button class="po-md-12 po-mt-1 po-lg-12" p-label="Enviar Orçamento"
                                p-icon="ph ph-share-fat" p-kind="primary" (p-click)="finalizarPedido()"
                                [p-loading]="confirmaLoading">
                            </po-button>
                        </div>


                    </div>

                </po-widget>
            </po-step>
        </po-stepper>

    </div>
</po-page-default>
<erpcloud-produto-add #produtoAddComponent>
</erpcloud-produto-add>

<erpcloud-cliente-add #clienteAddComponent>
</erpcloud-cliente-add>

<po-modal #informaDistModal p-size="sm" p-title="Distância não calculada" p-icon="ph ph-invoice" [p-primary-action]="primaryAction" p-click-out="false">
    <po-number p-label="Informe a distância em KM" p-help="Informe a distância em KM para o cálculo do orçamento"
        p-icon="ph ph-map-pin" p-placeholder="Informe a distância" p-required="true" [p-show-required]="true"
        [(ngModel)]="totalKm" [p-min]=1>
    </po-number>
</po-modal>