import { Component, OnInit, ViewChild } from "@angular/core";
import { PoBreadcrumb, PoDialogService, PoNotificationService, PoPageAction, PoTableAction, PoTableColumn, PoTableColumnLabel, PoTableColumnSpacing, PoTableComponent, PoTableDetail } from "@po-ui/ng-components";
import { environment } from "../../../../environments/environment";
import { OrcamentoService } from "../orcamento.service";
import { UserService } from "../../../core/user/user.service";
import { User } from "../../../core/user/user";
import { Vendedor } from "../../vendedor/vendedor";
import { Router } from "@angular/router";
import { OrcamentoToPedidoDto } from "../orcamento-pedido-dto";

@Component({
    selector: 'erpcloud-orcamento-consulta',
    templateUrl: './orcamento-consulta.component.html',
    styleUrls: ['./orcamento-consulta.component.css']
})
export class OrcamentoConsultaComponent implements OnInit {

    @ViewChild('orcamentoTable', { static: true }) orcamentoTable!: PoTableComponent;


    isHideLoading = true;
    userInfo!: User;
    vendedor!: Vendedor;
    tableItens: any[] = [];
    poTableColumnSpacing: PoTableColumnSpacing = PoTableColumnSpacing.Medium;

    //pagnation
    moreItens: boolean = false;
    page: number = 1;
    pageSize: number = 20;

    readonly orcamentoServiceApi = environment.apiUrl + environment.versionApi + '/comercial/orcamento';

    readonly pageActions: Array<PoPageAction> = [
        {
            label: 'Atualizar Dados',
            action: this.resetTable.bind(this),
            icon: 'ph ph-floppy-disk',

        }
    ];

    readonly actions: PoTableAction[] = [
        {
            label: 'Enviar para Comercial',
            action: this.openConfirmDialog.bind(this),
            icon: 'ph ph-check-circle',

        },
        {
            label: 'Visualizar Orçamento',
            action: this.visualizaOrcamento.bind(this),
            icon: 'ph ph-eye',

        },
        {
            label: 'Excluir Orçamento',
            type: 'danger',
            action: this.deleteOrcamento.bind(this),
            icon: 'ph ph-seal-warning',
            separator: true
        }
    ];
    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Comercial' }, { label: 'Meus Pedidos' }]
    };

    public readonly columns: Array<PoTableColumn> = [];

    constructor(private orcamentoService: OrcamentoService,
        private poDialog: PoDialogService,
        private router: Router,
        private poNotification: PoNotificationService,
        private userService: UserService) {

        this.columns = orcamentoService.orcamentoColumns;
        this.userService.getUser().subscribe((data) => {
            this.userInfo = data as User;
            this.orcamentoService.getVendedorById(this.userInfo.id_vendedor).subscribe((data) => {
                this.vendedor = data;
                console.log(this.vendedor);

                if (this.vendedor.id == 0) {
                    this.poNotification.error('Usuário sem vendedor associado, favor verificar cadastro');
                    this.router.navigate(['/home']);
                }
            });
        });
    }
    ngOnInit(): void {
        this.loadMore();
    }
    resetTable() {
        this.loadMore(true);
    }
    loadMore(reset: boolean = false): void {

        this.isHideLoading = false;
        if (reset) {
            this.page = 1;
            this.orcamentoService.getOrcamentosVendedor(this.page, this.pageSize).subscribe((data) => {
                this.tableItens = [];
                this.tableItens = [...this.tableItens, ...data.items];
                this.moreItens = data.hasNext;
                this.page++;
                this.isHideLoading = true;
            }, (error) => {
                console.log(error);
                this.isHideLoading = true;
            });
        }
        else {
            this.orcamentoService.getOrcamentosVendedor(this.page, this.pageSize).subscribe((data) => {
                this.tableItens = [...this.tableItens, ...data.items];
                this.moreItens = data.hasNext;
                this.page++;
                this.isHideLoading = true;

            }, (error) => {
                console.log(error);
                this.isHideLoading = true;

            });
        }

    }
    base64ToArrayBuffer(base64: string): ArrayBuffer {
        const binaryString = window.atob(base64); // Decodifica a string base64
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    visualizaOrcamento(orcamento: any) {

        this.isHideLoading = false;

        this.orcamentoService.visualizaOrcamento(orcamento.id).subscribe((data) => {
            const arrayBuffer = this.base64ToArrayBuffer(data);
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            this.isHideLoading = true;
        }, (error) => {
            console.log(error);
            this.isHideLoading = true;
        });

    }
    deleteOrcamento(orcamento: any) {
        this.isHideLoading = false;
        this.orcamentoService.deleteOrcamento(orcamento.id).subscribe((data) => {
            this.poNotification.success('Orçamento excluído com sucesso');
            this.orcamentoTable.applyFilters();
            this.isHideLoading = true;
        }, (error) => {
            this.isHideLoading = true;
        });
    }
    enviaFinanceiro(orcamento: any) {
        this.isHideLoading = false;
        var orcamentoFin: OrcamentoToPedidoDto = {
            idPedido: orcamento.id,
            obsFinanceiro: ""
        };
        this.orcamentoService.enviaOrcamentoComercial(orcamentoFin).subscribe((data) => {
            this.poNotification.success('Orçamento enviado para aprovação do financeiro');
            this.resetTable();
            this.isHideLoading = true;
        }, (error) => {
            this.isHideLoading = true;
        });
    }
    openConfirmDialog(orcamento: any) {
        this.poDialog.confirm({
            title: 'Envio de Orçamento - Aprovação Comercial',
            message: `<p><b>${this.userInfo.name}, você confirma o envio do orçamento número: ${orcamento.id.toString().padStart(5, '0')} para aprovação comercial?</b>.</p> <p>Após o envio para aprovação, essa operação não poderá ser desfeita. </p>`,
            confirm: () => this.enviaFinanceiro(orcamento)
        });
    }

}